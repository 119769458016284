<template>
    <!--新增对话框-->
    <el-dialog :title="editForm.reasonCancellationId ? '编辑运单取消原因' : '新增运单取消原因'" :visible.sync="dialogVisible" width="600px"
        :close-on-click-modal="false" :before-close="handleClose">
        <el-form :model="editForm" :rules="editFormRules" ref="editForm">
            <el-form-item label="取消原因：" prop="reasonCancellation" label-width="150px">
                <el-input v-model="editForm.reasonCancellation" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="取消原因类型：" prop="reasonCancellationType" label-width="150px">
                <el-radio-group v-model="editForm.reasonCancellationType">
                    <el-radio :label="1">货主</el-radio>
                    <el-radio :label="2">司机</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否退还订金：" prop="isRefund" label-width="150px">
                <el-radio-group v-model="editForm.isRefund">
                    <el-radio :label="0">不退还</el-radio>
                    <el-radio :label="1">退还</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('editForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            editForm: {
                reasonCancellation: null,
                isRefund:0,
                reasonCancellationType:1
            },
            dialogVisible: false,
            editFormRules: {
                reasonCancellation: [
                    { required: true, message: '请输入取消原因', trigger: 'blur' }
                ],
            },
        }
    },

    methods: {
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.editForm = {}
        },
        handleClose() {
            this.resetForm('editForm')
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$axios.post('/admin/reasoncancellation/' + (this.editForm.reasonCancellationId ? 'updateReasonCancellation' : 'saveReasonCancellation'), this.editForm)
                        .then(res => {
                            this.$emit('refreshDataList')
                            this.$message({
                                showClose: true,
                                message: '恭喜你，操作成功',
                                type: 'success',
                                onClose: () => { }
                            });
                            this.dialogVisible = false
                            this.resetForm(formName)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        init(reasonCancellationId) {
            if (reasonCancellationId != null && reasonCancellationId != '') {
                this.$axios.post('/admin/reasoncancellation/getReasonCancellationInfo', { reasonCancellationId: reasonCancellationId }).then(res => {
                    this.editForm = res.data
                })
            }
            this.dialogVisible = true;
        },

    }
}
</script>

<style scoped></style>
